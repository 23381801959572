@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

 .aside-transitions{
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out;
 }
 .aside-transitionsVisible{
    opacity: 1; 
 }